<template>
  <cw-page
    icon="assignment"
    class="confirmation__continuous-loan"
  >
    <template #hero-title>
      {{ title }}
    </template>

    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <cw-summary show-payment-info/>
        </v-col>

        <v-col
          class="pr-sm-4"
          cols="12"
          sm="6"
        >
          <v-alert
            v-if="offer.type === 'fixedPeriodContinuousLoan'"
            class="mb-0"
            type="info"
            prominent
            outlined
          >
            <p class="mb-0">
              <translate
                :translate-params="{ loanLimit: $filters.currency(offer.loanLimit, { locale }) }"
              >
                You have 45 days to make withdrawals from the %{ loanLimit } credit limit.
                The first invoice is sent after the 45 day period.
              </translate>
            </p>
          </v-alert>
          <cw-invoicing-method :form="form"/>
          <cw-pep :form="form"/>
          <cw-terms/>
        </v-col>
      </v-row>
    </validation-observer>

    <cw-payment-schedule
      @update-withdrawal-amount="setWithdrawalAmount($event)"
    />

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM') || $wait.waiting('page__init')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM') && !$wait.waiting('page__init')"
        color="purple-one darken-2"
        @click="
          validateAndSubmit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Approve loan offer
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwConfirmationContinuousLoan',

  components: {
    'cw-invoicing-method': () => import('@fi/pages/steps/ConfirmationInvoicingMethod'),
    'cw-payment-schedule': () => import('@fi/pages/steps/ConfirmationPaymentSchedule'),
    'cw-summary': () => import('@fi/pages/steps/ConfirmationSummary'),
    'cw-terms': () => import('@fi/pages/steps/ConfirmationTerms'),
    'cw-pep': () => import('@fi/pages/steps/ConfirmationPEP'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data() {
    return {
      form: {
        invoicingMethod: null,
        politicallyExposedPerson: 'off',
        withdrawalAmount: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
      isSaldoLimiitti: 'application/isSaldoLimiitti',
      isVippiJoustoluotto: 'application/isVippiJoustoluotto',
    }),

    ...mapState({
      terms: state => state.application.terms,
    }),

    title() {
      if (this.isSaldoLimiitti) return this.$gettext('We can offer you a Joustoluotto');
      if (this.isVippiJoustoluotto) return this.$gettext('We can offer you Vippi Flexible Credit');

      return this.$gettext('Check out your loan agreement.');
    },
  },

  created() {
    this.setSummary();
  },

  methods: {
    ...mapActions({
      setApplicationSummary: 'application/setApplicationSummary',
      setWithdrawalSummary: 'application/setWithdrawalSummary',
      submitForm: 'application/submit',
      acceptTerms: 'application/acceptTerms',
    }),

    setSummary() {
      const minPaymentMin = this.$filters.currency(this.offer.minPaymentAmountMin, {
        locale: this.locale,
      });
      const minPaymentMax = this.$filters.currency(this.offer.minPaymentAmountMax, {
        locale: this.locale,
      });

      const applicationSummary = [
        {
          id: 'loanLimit',
          name: this.$gettext('Credit limit'),
          value: this.$filters.currency(this.offer.loanLimit, {
            locale: this.locale,
          }),
        }, {
          id: 'monthlyInstalment',
          name: this.$gettext('Monthly Instalment'),
          value: minPaymentMin === minPaymentMax
            ? minPaymentMin
            : `${minPaymentMin} - ${minPaymentMax}`,
        }, {
          id: 'firstDueDate',
          name: this.$gettext('First due date'),
          value: this.$filters.date(this.offer.firstDueDate, {
            type: 'date',
            locale: this.locale,
          }),
        }, {
          id: 'interestRate',
          name: this.$gettext('Nominal interest'),
          value: `${this.offer.interestRate} %`,
        }, {
          id: 'annualPercentageRate',
          name: this.$gettext('The annual percentage rate'),
          value: this.$filters.percent(Number(this.offer.annualPercentageRate)),
        },
      ];

      const withdrawalSummary = [{
        id: 'withdrawalAmount',
        name: this.$gettext('First withdrawal'),
        value: this.$filters.currency(this.initialData.withdrawalAmount, {
          locale: this.locale,
        }),
      }];

      this.setApplicationSummary(applicationSummary);
      this.setWithdrawalSummary(withdrawalSummary);

      if (this.initialData.withdrawalAmount) {
        this.setWithdrawalAmount(this.initialData.withdrawalAmount);
      }
    },

    async setWithdrawalAmount(value) {
      this.form.withdrawalAmount = value;
    },

    async validateAndSubmit() {
      const valid = await this.$refs.observer.validate();
      this.$dataLayer.PushToECommerce({
        params: {
          action: 'stepValidationClick',
        },
      });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({
        params: {
          action: 'stepValidationSubmit',
        },
      });
      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      await Promise.all(
        this.terms.map(term => this.acceptTerms({
          termsName: term.name,
        })),
      );

      const formParams = {
        politicallyExposedPerson: this.form.politicallyExposedPerson,
        invoicingMethod: this.form.invoicingMethod,
        withdrawalAmount: this.form.withdrawalAmount,
      };

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Terms',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        // Handle errors
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
